<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <header class="container-header-index">
                <div class="h-index-left"></div>
                <div class="h-index-right">
                    <a-button class="btn-create-new btn-global btn-text-white" a-button type="primary" @click="showModal">
                        Registrar nuevo
                    </a-button>
                </div>
            </header>

            <a-row style="margin-top: 30px;" :gutter="24">
                <a-col :span="12" :lg="8" :xl="6" class="mb-24" v-for="(item, index) in this.listImages" :key="index">
                    <a-card :bordered="false" class="widget-2 h-full text-center">
                        <template #title>
                            <img :src="item.urlImage" alt="">
                            <h6>{{ item.name }}</h6>
                        </template>                        
                        <a-button class="bg-danger" @click="deleteImage(item._id, item.name)">Eliminar</a-button>
                    </a-card>
                </a-col>
            </a-row>

            <a-modal v-model="visibleModal" title="Subir nueva imagen" @ok="handleOk">
                <div class="div-select-custom-modal">
                    <input id="file-5" type="file" @click="loadFilePdf" @change="loadFilePdf()" name="file-5"
                        class="inputfile inputfile-5 input-anexo inputNew" required />
                    <label id="label" for="file-5" class="label-file label">
                        <figure>
                            <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20" height="17"
                                viewBox="0 0 20 17">
                                <path
                                    d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                </path>
                            </svg>
                        </figure>
                    </label>
                    <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar archivo *</h6>
                </div>
            </a-modal>
        </div>
    </div>
</template>

<script>
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';
import DefaultIndexHeader from '../../../components/Headers/DefaultIndexHeader.vue';

export default {
    components: {
        LoaderDefault,
        DefaultIndexHeader
    },

    data() {
        return {
            visibleModal: false,
            isLoading: false,
            selectedImg: File,
            listImages: [],
            dataImage: {
                _id: "",
                name: "",
                urlImage: ""
            }
        }
    },
    async mounted() {
        this.getImagesSlider()
    },
    methods: {
        async getImagesSlider() {
            this.listImages = []
            const result = await db.collection("imagesSlider").get()
            result.forEach(image => {
                const images = image.data()
                this.listImages.push(images)
            });
        },
        showModal() {
            this.visibleModal = true;
        },
        async deleteImage(idFile, nameFile) {
            const storageRef = storage.ref()
            const filePath = storageRef.child('imagesSlider')
                .child(idFile)
                .child(nameFile)
            try {
                await filePath.delete()
                await db.collection('imagesSlider')
                    .doc(idFile)
                    .delete()
                this.$swal
                    .fire({
                        title: 'Imagen eliminada',
                        text: 'Se ha eliminado la imagen exitosamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    });
                await this.getImagesSlider()
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
            }
        },
        async handleOk() {
            try {
                const collectionRef = db.collection('imagesSlider');
                const docRef = await collectionRef.add(this.dataImage);
                const docId = docRef.id;

                const storageRef = storage.ref();
                const imgRef = storageRef
                    .child('imagesSlider')
                    .child(docId)
                    .child(this.selectedFile.name);

                const reader = new FileReader();
                reader.onload = async () => {
                    const imgDataURL = reader.result;
                    await imgRef.putString(imgDataURL, 'data_url');
                    const pdfUrl = await imgRef.getDownloadURL();
                    this.dataImage._id = this.selectedFile.name;
                    this.dataImage.urlImage = pdfUrl;
                    this.dataImage.name = this.selectedFile.name

                    await collectionRef.doc(docId).update({
                        _id: docId,
                        name: this.selectedFile.name,
                        urlImage: pdfUrl
                    });
                    await this.getImagesSlider()
                    this.dataImage = {};
                };
                this.$swal
                    .fire({
                        title: 'Imagen Creada',
                        text: 'Imagen Creada correctamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    });
                reader.readAsDataURL(this.selectedFile);
                this.isLoading = false
                this.visibleModal = false;
                this.getImagesSlider()

            } catch (error) {
                this.visibleModal = false;
            }
        },

        loadFilePdf() {
            const input = document.getElementById('file-5');
            input.accept = "image/*";
            input?.addEventListener('change', (e) => {
                const target = e.target;
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]                    
                }
            });
        },
    },

}
</script>